import React from 'react';


const EmailSVG = () => {
    return(
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M1.80146 0.234579H24.2473C25.1525 0.234579 25.8862 0.968323 25.8862 1.87344V24.3193C25.8862 25.2244 25.1525 25.9582 24.2473 25.9582H1.80146C0.89634 25.9582 0.162598 25.2244 0.162598 24.3193V1.87344C0.162598 0.968323 0.89634 0.234579 1.80146 0.234579ZM20.4432 5.62966H5.68955C4.66601 5.62966 3.84534 6.45034 3.84534 7.47387V18.5391C3.84534 19.5534 4.66601 20.3833 5.68955 20.3833H20.4432C20.6854 20.3833 20.9252 20.3356 21.1489 20.2429C21.3727 20.1502 21.576 20.0144 21.7472 19.8431C21.9185 19.6719 22.0543 19.4686 22.147 19.2448C22.2397 19.0211 22.2874 18.7813 22.2874 18.5391V7.47387C22.2874 6.98476 22.0931 6.51568 21.7472 6.16982C21.4014 5.82396 20.9323 5.62966 20.4432 5.62966ZM20.4432 7.47387L13.0664 12.0844L5.68955 7.47387H20.4432ZM20.4432 18.5391H5.68955V9.65925L13.0664 14.2605L20.4432 9.65925V18.5391Z"/>
</svg>
    )
}

export default EmailSVG;