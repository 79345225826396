import React from "react";

const LinkedinSVG = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.1509 22.1563H18.3008V16.1233C18.3008 14.6846 18.2715 12.8332 16.2944 12.8332C14.287 12.8332 13.9804 14.3986 13.9804 16.0171V22.1563H10.1302V9.75H13.8288V11.4411H13.8786C14.3953 10.4661 15.652 9.43692 17.5294 9.43692C21.4305 9.43692 22.152 12.0044 22.152 15.3465V22.1563H22.1509ZM5.78175 8.05242C4.54242 8.05242 3.54683 7.04925 3.54683 5.81533C3.54683 4.5825 4.5435 3.58042 5.78175 3.58042C7.01675 3.58042 8.01775 4.5825 8.01775 5.81533C8.01775 7.04925 7.01567 8.05242 5.78175 8.05242ZM7.71225 22.1563H3.85125V9.75H7.71225V22.1563ZM24.0771 0H1.91858C0.858 0 0 0.8385 0 1.87308V24.1269C0 25.1626 0.858 26 1.91858 26H24.0738C25.1333 26 26 25.1626 26 24.1269V1.87308C26 0.8385 25.1333 0 24.0738 0H24.0771Z" />
    </svg>
  );
};

export default LinkedinSVG;
