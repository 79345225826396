import React from 'react';


const GithubSVG = () => {
    return(
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 0.321751C5.8175 0.321751 0 6.1425 0 13.3218C0 19.0667 3.7245 23.9384 8.88875 25.6555C9.53875 25.7779 9.77708 25.376 9.77708 25.0304C9.77708 24.7217 9.76625 23.9038 9.76083 22.8204C6.14467 23.6048 5.382 21.0763 5.382 21.0763C4.7905 19.5758 3.93575 19.175 3.93575 19.175C2.75817 18.369 4.02675 18.3853 4.02675 18.3853C5.33217 18.4763 6.01792 19.7243 6.01792 19.7243C7.17708 21.7122 9.061 21.138 9.80417 20.8054C9.92117 19.9648 10.2559 19.3917 10.6275 19.0667C7.74042 18.7417 4.706 17.6237 4.706 12.6425C4.706 11.2233 5.20975 10.0642 6.04392 9.15417C5.89767 8.82592 5.45892 7.50425 6.15767 5.7135C6.15767 5.7135 7.24642 5.36467 9.73267 7.046C10.7727 6.75675 11.8777 6.61375 12.9827 6.60725C14.0877 6.61375 15.1927 6.75675 16.2327 7.046C18.7027 5.36467 19.7914 5.7135 19.7914 5.7135C20.4902 7.50425 20.0514 8.82592 19.9214 9.15417C20.7502 10.0642 21.2539 11.2233 21.2539 12.6425C21.2539 17.6367 18.2152 18.7362 15.3227 19.0558C15.7777 19.4458 16.2002 20.2432 16.2002 21.4608C16.2002 23.2007 16.1839 24.5982 16.1839 25.0207C16.1839 25.3619 16.4114 25.7682 17.0777 25.6382C22.2788 23.933 26 19.058 26 13.3218C26 6.1425 20.1793 0.321751 13 0.321751Z"/>
        </svg>        
    )
}

export default GithubSVG;